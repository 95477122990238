'use client';

import * as React from 'react';
import { Loader2, LucideIcon } from 'lucide-react';
import { Slot } from '@radix-ui/react-slot';
import Form, { FormProps } from './form/form';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'mxcn';
import mergeRefs from 'merge-refs';
import { motion } from 'motion/react';
import { formIsSubmittingAtom, formIsValidAtom } from './form/atoms';
import { usePeekHydratedAtomValue } from '@/lib/atoms';
import useIsHydrated from '@/lib/hooks/use-is-hydrated';
const buttonVariants = cva(['inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-md text-sm transition-colors font-medium', '[&_svg]:opacity-70 [&_svg]:hover:opacity-100', 'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-focus-500/40', 'disabled:opacity-50'].join(' '), {
  variants: {
    variant: {
      none: '',
      default: 'bg-contrast-700 text-contrast-0 shadow hover:bg-contrast-600 hover:bg-opacity-90 focus-visible:text-contrast-0 focus-visible:ring-4 focus-visible:ring-contrast-600',
      primary: 'bg-brand-900 text-brand-50 shadow hover:bg-brand-800 hover:bg-opacity-90',
      success: 'bg-green-600 text-white shadow hover:bg-green-500 hover:bg-green-500',
      destructive: 'bg-red-500 text-gray-50 shadow-sm hover:bg-red-500/90',
      secondaryDestructive: 'bg-contrast-0 text-red-700 shadow-sm hover:bg-red-100 hover:bg-opacity-80 border border-red-200',
      secondarySuccess: 'bg-contrast-0 text-green-700 shadow-sm hover:bg-green-100 hover:bg-opacity-80 border border-green-200',
      outline: 'border border-contrast-200 bg-white shadow-sm hover:bg-contrast-100 hover:text-contrast-900 [&_svg]:opacity-100',
      secondary: 'bg-contrast-0 text-contrast-900 shadow-sm hover:bg-contrast-100 hover:bg-opacity-80 border border-contrast-300',
      ghost: 'hover:bg-contrast-500/20 hover:text-contrast-900',
      flat: 'bg-contrast-500/20 text-contrast-900 hover:bg-contrast-500/20 hover:text-contrast-900',
      link: 'text-contrast-900 underline-offset-4 hover:underline'
    },
    size: {
      none: '',
      default: 'h-9 px-4 py-2',
      xs: 'h-5 rounded-md px-1 text-xs',
      sm: 'h-7 rounded-md px-2 text-sm',
      lg: 'h-10 rounded-md px-4 text-base',
      xl: 'h-14 rounded-md px-6',
      icon: 'p-1 [&_svg]:opacity-100'
    },
    active: {
      true: '',
      false: ''
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    active: false
  },
  compoundVariants: [{
    active: true,
    variant: 'secondary',
    class: 'bg-contrast-200'
  }]
});
export interface ButtonProps<T extends Record<string, unknown>> extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  action?: FormProps<T, unknown>['onSubmit'];
  args?: T;
  active?: boolean;
  onSuccess?: () => void;
  refreshOnSuccess?: boolean;
  as?: string;
  asChild?: boolean;
  icon?: LucideIcon;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => unknown | Promise<unknown>;
}
function Button<T extends Record<string, unknown>>({
  action,
  args,
  active,
  onSuccess,
  refreshOnSuccess,
  children,
  className,
  variant,
  size,
  asChild = false,
  as,
  icon: Icon,
  ref,
  ...props
}: ButtonProps<T> & {
  ref?: React.Ref<HTMLButtonElement>;
}) {
  const Comp = as ?? (asChild ? Slot : 'button' as unknown as React.ComponentType<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>>);
  const [isHandlingOnClick, setIsHandlingOnClick] = React.useState(false);
  const wrappedOnClick = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsHandlingOnClick(true);
    await props.onClick?.(e);
    setIsHandlingOnClick(false);
  }, [props]);
  const isFormSubmitting = usePeekHydratedAtomValue(formIsSubmittingAtom);
  const formIsValid = usePeekHydratedAtomValue(formIsValidAtom);
  const isInsideForm = typeof formIsValid !== 'symbol';
  const isSubmitButton = props.type === 'submit';
  const showLoadingSpinner = isInsideForm && isFormSubmitting && isSubmitButton || isHandlingOnClick;
  const isHydrated = useIsHydrated();
  const disabled = Boolean(props.disabled || !isHydrated || showLoadingSpinner || isInsideForm && isSubmitButton && !formIsValid);
  const dimensionsRef = React.useRef<HTMLFormElement | HTMLButtonElement>(null);
  if (showLoadingSpinner) {
    props.style ??= {};
    const dimensions = dimensionsRef.current?.getBoundingClientRect();
    if (dimensions) {
      props.style.width = dimensions.width;
      props.style.height = dimensions.height;
    }
    children = <motion.div className="flex items-center justify-center h-full cursor-wait" initial={{
      opacity: 0,
      y: 10
    }} animate={{
      opacity: 1,
      y: 0
    }}>
        <Loader2 className="animate-spin h-3/4" />
        <span className="sr-only">Working ...</span>
      </motion.div>;
  }
  if (action) {
    return <Form onSubmit={action} className="contents" onSuccess={onSuccess} args={args} refreshOnSuccess={refreshOnSuccess} ref={dimensionsRef as React.RefObject<HTMLFormElement | null>}>
        <Button variant={variant} size={size} className={className} disabled={disabled} {...props} onClick={wrappedOnClick} type="submit" ref={ref}>
          {children}
        </Button>
      </Form>;
  }
  return <Comp className={cn(!isHydrated && 'pointer-events-none', buttonVariants({
    variant,
    active,
    size,
    className
  }))} ref={mergeRefs(ref, dimensionsRef as React.Ref<HTMLButtonElement>)} draggable="false" {...props} disabled={disabled} onClick={wrappedOnClick} data-sentry-element="Comp" data-sentry-component="Button" data-sentry-source-file="button.tsx">
      {children}
      {Icon && <Icon className="ml-[0.5em] w-[1.3em] h-[1.3em]" />}
    </Comp>;
}
export { Button, buttonVariants };