'use client';

import { useAtomValue } from 'jotai';
import cn from 'mxcn';
import { formSubmissionErrorsAtom } from './atoms';
export function FormErrors({
  className
}: {
  className?: string;
}) {
  const errors = useAtomValue(formSubmissionErrorsAtom).filter(e => !e.path);
  if (errors.length === 0) return null;
  return <ul role="alert" className={cn('bg-amber-300 text-contrast-800 p-2 rounded text-sm leading-snug', errors.length > 1 ? 'list-disc list-inside' : '', className)} data-sentry-component="FormErrors" data-sentry-source-file="form-errors.tsx">
      {errors.map((e, i) => <li key={i}>{e.message}</li>)}
    </ul>;
}