'use client';

import { useAtomValue } from 'jotai';
import { formFieldStatesAtom } from './atoms';
import { useEffect, useRef, useState } from 'react';
import { GripVertical } from 'lucide-react';
import { motion } from 'motion/react';
import { Portal } from '@radix-ui/react-portal';
function FormFieldState({
  state
}: {
  state: any;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  return <div key={state.id} className="stack font-mono px-2" data-sentry-component="FormFieldState" data-sentry-source-file="debug.tsx">
      <button onClick={() => setIsExpanded(!isExpanded)} className="flex items-center gap-2 text-sm text-contrast-200 font-semibold hover:text-contrast-100 pb-1">
        <span className="w-4">{isExpanded ? '▼' : '▶'}</span>
        {state.name}
      </button>
      {isExpanded && <div className="text-sm text-contrast-200 pl-6 grid grid-cols-[max-content_1fr] pb-2">
          <div className="text-contrast-400 pr-6 border-b border-contrast-700">
            Value
          </div>
          <div className="text-contrast-100 pr-3 border-b border-contrast-700">
            {JSON.stringify(state.value)}
          </div>
          <div className="text-contrast-400 pr-6 border-b border-contrast-700">
            Type
          </div>
          <div className="text-contrast-100 pr-3 border-b border-contrast-700">
            {typeof state.value}
          </div>
          <div className="text-contrast-400 pr-6 border-b border-contrast-700">
            Changed
          </div>
          <div className="text-contrast-100 pr-3 border-b border-contrast-700">
            {state.changed ? '✅' : '❌'}
          </div>
          <div className="text-contrast-400 pr-6 border-b border-contrast-700">
            Blurred
          </div>
          <div className="text-contrast-100 pr-3 border-b border-contrast-700">
            {state.blurred ? '✅' : '❌'}
          </div>
          <div className="text-contrast-400 pr-6 border-b border-contrast-700">
            Validation errors
          </div>
          <div className="text-contrast-100 pr-3 border-b border-contrast-700">
            {state.localErrors.map((error: any, i: number) => <div key={i}>{error.message}</div>)}
            {state.localErrors.length === 0 && <div className="text-contrast-400">No errors</div>}
          </div>
        </div>}
    </div>;
}
export default function FormDebug(props: {
  name?: string;
  args?: Record<string, unknown>;
}) {
  const formStates = useAtomValue(formFieldStatesAtom);
  const [position, setPosition] = useState({
    top: 0,
    left: 0
  });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = ref.current;
    if (el) {
      const formEl = el.parentElement! as HTMLFormElement;
      const formRect = formEl.getBoundingClientRect();
      setPosition({
        top: formRect.top + window.scrollY - 5,
        left: formRect.right + window.scrollX - 5
      });
    }
  }, [formStates]);
  return <div ref={ref} data-sentry-component="FormDebug" data-sentry-source-file="debug.tsx">
      <Portal data-sentry-element="Portal" data-sentry-source-file="debug.tsx">
        <motion.div style={{
        top: position.top,
        left: position.left
      }} drag dragMomentum={false} className="stack text-xs absolute z-[100] rounded-lg shadow-2xl bg-contrast-800 text-contrast-50 max-h-[50vh] overflow-y-auto max-w-[50vw]" data-sentry-element="unknown" data-sentry-source-file="debug.tsx">
          <div className="bg-contrast-700 cursor-move p-2 font-mono font-bold leading-none row-2 items-center">
            <GripVertical size={10} className="opacity-50" data-sentry-element="GripVertical" data-sentry-source-file="debug.tsx" />
            {props.name ?? 'Unnamed Form'}
          </div>
          <div className="stack py-2">
            {Object.values(formStates).map(state => <FormFieldState key={state.id} state={state} />)}
            {props.args && <>
                <div className="text-contrast-400 bg-contrast-900 m-1 rounded p-1 leading-none">
                  Form Args
                </div>
                <div className="text-contrast-100 font-mono text-xs whitespace-pre p-2">
                  {JSON.stringify(props.args, null, 2)}
                </div>
              </>}
          </div>
        </motion.div>
      </Portal>
    </div>;
}