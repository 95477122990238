'use client';

import { ActionError, ActionResult } from '@/lib/action';
import { ScopeProvider } from 'jotai-scope';
import { useHydrateAtoms, useAtomCallback } from 'jotai/utils';
import { FormEvent, useCallback, useEffect, useId } from 'react';
import { useRouter } from 'next/navigation';
import { z, ZodSchema } from 'zod';
import { FormErrors } from './form-errors';
import { fieldStateAtomsAtom, formIdAtom, formUploadsAtom, formIsSubmittingAtom, formLastResultAtom, formSubmissionErrorsAtom, UNSUBMITTED, formIsValidAtom, formValidationAtom, formCallbacksAtom, formValuesAtom } from './atoms';
import { useSyncAtoms } from '@/lib/atoms';
import { useDebounceCallback } from 'usehooks-ts';
import FormDebug from './debug';
import { clearFormProgress, saveFormProgress } from './actions';
import { fetchApi } from '@/lib/fetch-api';
export type FormProps<Values extends Record<string, unknown>, Result> = Omit<React.ComponentPropsWithRef<'form'>, 'onSubmit'> & {
  /**
   * The function to call when the form is submitted.
   */
  onSubmit?: (values: Values) => Promise<ActionResult<Result>>;
  /**
   * The function to call when the form is successfully submitted.
   */
  onSuccess?: string | ((data: Result) => void);
  /**
   * The function to call when the form is submitted and there are errors.
   */
  onError?: (errors: ActionError[]) => void;
  /**
   * Additional values to include when invoking the `onSubmit` function that
   * will override any values in the form. Useful to set fixed values, i.e. the
   * id of the object that the form updates.
   */
  args?: Partial<Values>;
  /**
   * If true, the form will automatically submit when the user changes a field.
   */
  autosubmit?: boolean;
  /**
   * If true, the form will refresh the page when the form is successfully
   * submitted.
   */
  refreshOnSuccess?: boolean;
  /**
   * The minimum number of milliseconds the onSubmit action should take. This is
   * useful if you want to show a loading indicator for a minimum amount of time.
   */
  minDuration?: number;
  /**
   * A Zod schema to validate the form values against. Use Field.validation
   * where possible, it automatically maps errors back to the field. This option
   * is useful for validating the form as a whole, where the validation depends
   * on the related values across fields.
   */
  validation?: ZodSchema;
  /**
   * A function that is called whenever the form values change
   */
  onValuesChange?: (values: Values) => void;
  /**
   * If true, the form will render a debug panel.
   */
  debug?: boolean;
  /**
   * Custom ID for saving progress.
   */
  progressId?: string | null;
  children: React.ReactNode;
};
export default function Form<Values extends Record<string, unknown>, Result>(props: FormProps<Values, Result>) {
  return <ScopeProvider atoms={[formIdAtom, formLastResultAtom, formSubmissionErrorsAtom, formIsSubmittingAtom, formUploadsAtom, formValidationAtom, fieldStateAtomsAtom, formCallbacksAtom]} data-sentry-element="ScopeProvider" data-sentry-component="Form" data-sentry-source-file="form.tsx">
      <FormInner {...props} data-sentry-element="FormInner" data-sentry-source-file="form.tsx" />
    </ScopeProvider>;
}
function FormInner<Values extends Record<string, unknown>, Result>({
  id,
  onSubmit,
  onError,
  onSuccess,
  autosubmit,
  args,
  minDuration,
  refreshOnSuccess,
  validation,
  onValuesChange,
  debug,
  progressId = null,
  children,
  ...props
}: FormProps<Values, Result>) {
  const _id = useId();
  id ??= _id;
  minDuration ??= 500;
  const router = useRouter();
  useHydrateAtoms([[formIdAtom, id], [formLastResultAtom, UNSUBMITTED], [formSubmissionErrorsAtom, []], [formIsSubmittingAtom, false], [formUploadsAtom, []], [formValidationAtom, validation], [fieldStateAtomsAtom, {}]]);
  const loadSavedProgress = useCallback(async () => {
    if (progressId) {
      try {
        const response = await fetchApi(`/component/form?formId=${progressId}`, z.object({
          data: z.object({
            values: z.record(z.any())
          })
        }));
        if (response?.data) {
          applyValues(response.data.values);
        }
      } catch (err) {
        console.error('Error loading form progress:', err);
      }
    } else {
      progressId = null;
    }
  }, [progressId]);
  const applyValues = useAtomCallback(useCallback((get, set, values: any) => {
    const fieldStateAtoms = get(fieldStateAtomsAtom);

    // Update each field with its saved value
    Object.entries(fieldStateAtoms).forEach(([name, atom]) => {
      if (values[name] !== undefined) {
        const current = get(atom);
        set(atom, {
          ...current,
          value: values[name],
          touched: true
        });
      }
    });
  }, []));
  useEffect(() => {
    loadSavedProgress();
  }, [loadSavedProgress]);
  const handleSubmit = useAtomCallback(useCallback(async (get, set, e?: FormEvent) => {
    e?.preventDefault();
    if (!onSubmit) return;
    // Don't bother with invalid forms
    const formIsValid = get(formIsValidAtom);
    if (!formIsValid) return;
    set(formIsSubmittingAtom, true);

    // Collect the form field values
    const fieldStateAtoms = get(fieldStateAtomsAtom);
    const fieldStates = Object.values(fieldStateAtoms).map(get);

    // Set all fields as blurred to trigger errors to show up, since
    // submitting is kind of "touching every field"
    Object.values(fieldStateAtoms).forEach(fieldAtom => {
      set(fieldAtom, {
        ...get(fieldAtom),
        blurred: true
      });
    });
    if (fieldStates.some(field => field.localErrors.length > 0)) {
      set(formIsSubmittingAtom, false);
      return;
    }
    const values = get(formValuesAtom) as Values;
    Object.assign(values, args);

    // Wait for all uploads to complete
    await Promise.all(get(formUploadsAtom));
    set(formUploadsAtom, []);

    // Submit the form
    const [result] = await Promise.all([onSubmit(values), new Promise(resolve => setTimeout(resolve, minDuration))]);

    // Callbacks
    if (result.errors) {
      onError?.(result.errors);
    } else {
      // Clear saved progress on successful submission
      if (progressId) {
        await clearFormProgress({
          formId: progressId
        });
      }
      if (typeof onSuccess === 'string') {
        router.push(onSuccess);
      } else {
        onSuccess?.(result.data!);
        if (refreshOnSuccess) {
          router.refresh();
        }
      }
    }

    // Update the form state
    set(formLastResultAtom, result);
    set(formSubmissionErrorsAtom, result.errors ?? []);
    set(formIsSubmittingAtom, false);
  }, [args, minDuration, onError, onSubmit, onSuccess, refreshOnSuccess, router, progressId]));
  const originalOnValuesChange = onValuesChange;
  onValuesChange = useDebounceCallback(useCallback((values: Values) => {
    if (autosubmit) {
      handleSubmit();
    }

    // Save form progress if enabled
    if (progressId) {
      saveFormProgress({
        formId: progressId,
        values
      });
    }
    originalOnValuesChange?.({
      ...values,
      ...args
    });
  }, [autosubmit, originalOnValuesChange, args, handleSubmit, progressId]), 500);
  useSyncAtoms([[formCallbacksAtom, {
    onValuesChange
  }]]);
  return <form {...props} onSubmit={handleSubmit} id={id} data-sentry-component="FormInner" data-sentry-source-file="form.tsx">
      <FormErrors data-sentry-element="FormErrors" data-sentry-source-file="form.tsx" />
      {children}
      {debug && <FormDebug args={args} />}
    </form>;
}