export function getEnvVariable(name: string): string {
  const value = process.env[name];
  if (!value) {
    throw new Error(`'${name}' environment variable not set.`);
  }
  return value;
}

const env = {
  appName: 'opened',

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  get baseUrl() {
    if (env.isProduction) {
      return 'https://opened.co';
    } else if (env.isPreview) {
      return process.env.NEXT_PUBLIC_BASE_URL!;
    } else if (env.isTest) {
      return 'http://localhost:3001';
    }
    return 'http://localhost:3000';
  },

  isVercel: process.env.VERCEL,

  get isVercelPreviewEnv() {
    return process.env.VERCEL_ENV === 'preview';
  },

  get marketingSiteUrl() {
    return (
      process.env.NEXT_PUBLIC_MARKETING_SITE_URL ?? 'https://connect.opened.co'
    );
  },

  branch: process.env.BRANCH,

  get isDevelopment() {
    return env.environment === 'development';
  },
  get isTest() {
    return env.environment === 'test';
  },
  get isPreview() {
    return env.environment === 'preview';
  },
  get isProduction() {
    return env.environment === 'production';
  },

  get isBrowser() {
    return typeof window !== 'undefined';
  },
  get isLocal() {
    return env.isDevelopment || env.isTest;
  },
};

export default env;
