import env from '@/config/environment';
import { ZodType } from 'zod';

export async function fetchApi<T>(
  path: string,
  responseSchema: ZodType<T>,
  init: RequestInit = {}
): Promise<T> {
  if (!init.headers) {
    init.headers = [['content-type', 'application/json']];
  } else {
    const headers = new Headers(init.headers);
    headers.set('content-type', 'application/json');
    init.headers = headers;
  }
  const url = new URL(`/api${path}`, env.baseUrl);
  const response = await fetch(url, init);
  const json = await response.json();
  return responseSchema.parse(json);
}
